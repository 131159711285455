import React, { useEffect, useState } from "react";

import "../css/app.css";

import "../css/form.css";

import { Trans, useTranslation } from "react-i18next";

import { getAccessToken, getOTP, verifyOTP, postTrackingData } from "../utils";

import { ErrorModal } from "./ErrorModal";

export const Form = ({ onSignUpComplete }) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [loginCode, setLoginCode] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);
  const [accessToken, setAccessToken] = useState("");

  const handleEmailSubmit = async (event) => {
    event.preventDefault();
    postTrackingData("button_click", {"value":"page_enter_email_next"})
    try {
      setLoading(true);
      const token = await getAccessToken();
      setAccessToken(token);
      const res = await getOTP({
        email,
        client: "web",
        access_token: token,
      });
      if (res.ok) {
        setIsValid(true);
        postTrackingData("page_view", {"value":"page_enter_otp"})
      } else {
        document.getElementById("modal-background").style.display = "block";
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
    postTrackingData("button_click", {"value":"page_enter_otp_sign_up"})
    const referralCode = new URLSearchParams(window.location.search).get(
      "code"
    );

    try {
      setLoading(true);
      const res = await verifyOTP({
        email,
        referral_code: referralCode,
        client: "web",
        access_token: accessToken,
        otp: loginCode,
      });
      if (res.ok) {
        onSignUpComplete();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleResend = async () => {
    postTrackingData("button_click", {"value": "page_enter_otp_resend"})
    try {
      await getOTP({
        email,
        client: "web",
        access_token: accessToken,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (loading) {
      document.getElementById("spinner").style.display = "inline-block";
    } else {
      document.getElementById("spinner").style.display = "none";
    }
  }, [loading]);

  useEffect(() => {
    let countdown;
    if (isValid) {
      countdown = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft === 0) {
            clearInterval(countdown);
            return 0;
          } else {
            return prevTimeLeft - 1;
          }
        });
      }, 1000);
    }

    return () => clearInterval(countdown);
  }, [isValid]);

  const validateForm = (
    <>
      <div id="form-container">
        <div>
          <div className="h1">{t("validate-form-1")}</div>
          <div id="content-2" className="body-2">
            {t("validate-form-2")}
            <br />
            {t("validate-form-3")}
          </div>
        </div>
        <div className="form">
          <form onSubmit={handleEmailSubmit} id="validate-form">
            <input
              required
              className="form-input body-1"
              type="email"
              placeholder={t("place-holder-1")}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <button
              className="form-button body-1 fw-600"
              disabled={loading}
              type="submit"
            >
              <i id="spinner" />
              {t("next")}
            </button>
          </form>
        </div>
      </div>
      <ErrorModal email={email} accessToken={accessToken} />
    </>
  );

  const registerForm = (
    <div id="form-container">
      <div>
        <div className="h1">{t("sign-up-form-1")}</div>
        <div id="content-2" className="body-2">
          {t("sign-up-form-2")}
        </div>
      </div>
      <div className="form">
        <form onSubmit={handleSignUp} id="sign-up-form">
          <input
            required
            className="form-input body-1"
            type="number"
            placeholder={t("place-holder-2")}
            onChange={(e) => setLoginCode(e.target.value)}
            value={loginCode}
          />
          <div style={{display: "inline-block"}}>
            <button
              className="form-button body-1 fw-600"
              disabled={loading}
              type="submit"
            >
              <i id="spinner" className="fa fa-spinner fa-spin" />
              {t("sign-up")}
            </button>
          </div>
        </form>
        <div id="count-down" className="body-1">
          {timeLeft !== 0 ? `${t("sign-up-form-3")} (${timeLeft})` : ""}
          {timeLeft === 0 ? <span onClick={handleResend}>{t("resend")}</span> : ""}
        </div>
        <div id="sign-up-hint" className="body-1">
          <Trans
            i18nKey="sign-up-hint"
            components={{
              a: <a href="https://heymandi.com/terms" target="_blank" rel="noreferrer"/>,
              u: <u />,
            }}
          />
        </div>
      </div>
    </div>
  );

  return isValid ? registerForm : validateForm;
};
