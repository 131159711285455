import React, { useState } from "react";

import { Helmet } from "react-helmet";

import { useTranslation } from "react-i18next";

import CookieConsent, { Cookies } from "react-cookie-consent";

import "./css/app.css";

import { ErrorModal, Congratulation, Form } from "./components";

export default function App() {
  // useTranslation return translate function and i18n instance
  const { t, i18n } = useTranslation();
  const [ isSignUpSuccess, setIsSignUpSuccess ] = useState(false);

  const [shouldUseCookies, setShouldUseCookies] = useState(
    Cookies.get("cookies_consent") === undefined ? true : JSON.parse(Cookies.get("cookies_consent"))
  );

  const imagePath = "https://dteklg4bowqr.cloudfront.net/referral/website/"

  const helmet = (
    <Helmet>
      <title>{t("site-name")}</title>
      <meta name="title" content={t("site-name")} />
      <meta name="description" content={t("description")} />

      <meta property="og:url" content="https://referral.heymandi.com" />
      <meta property="og:site_name" content={t("site-name")} />
      <meta property="og:title" content={t("site-name")} />
      <meta property="og:description" content={t("description")} />
      <meta
        property="og:image"
        content={`${imagePath}icon.png`}
      />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={i18n.language} />

      <meta property="twitter:title" content={t("site-name")} />
      <meta property="twitter:description" content={t("description")} />
      <meta
        property="twitter:image"
        content={`${imagePath}icon.png`}
      />
      <meta property="twitter:url" content="https://referral.heymandi.com" />
      <meta property="twitter:card" content="summary" />
    </Helmet>
  );

  const GAHelmet = shouldUseCookies ? (
    <Helmet>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-09E1HM52Z4"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
  
          gtag('config', 'G-09E1HM52Z4');
          gtag('event', 'page_view', {"value":"page_enter_email"})
        `}
      </script>
    </Helmet>
  ) : (
    <></>
  );

  const handleSignUpComplete = () => {
    document.getElementById("main").style.display = "none";
    setIsSignUpSuccess(true)
  }

  const handleDeclineCookies = () => {
    setShouldUseCookies(false);
    window.gtag = undefined;
  }

  return (
    <div>
      {GAHelmet}
      {helmet}
      {isSignUpSuccess && <Congratulation imagePath={imagePath} />}
      <div id="main">
        <div id="header-container">
          <div id="header" className="position-absolute">
            <img id="header-logo" src={`${imagePath}logo.png`} alt=""/>
            <div id="header-content" className="h1">
              {t("header-1")} <br />
              {t("header-2")} <br />
              {t("header-3")}
            </div>
          </div>
          <img id="background" alt=""/>
        </div>
        <Form onSignUpComplete={handleSignUpComplete}/>
      </div>
      <CookieConsent
        location="bottom"
        buttonText={t("accept")}
        cookieName="cookies_consent"
        expires={7}
        flipButtons
        
        style={{background: "#2B373B", color: "#FAFAFA"}}
        buttonStyle={{
          color: "#4e503b",
          fontSize: "14px",
          borderRadius: "23px",
          height: "46px",
          width: "100px",
        }}  
        onAccept={() => setShouldUseCookies(true)}
        
        enableDeclineButton
        declineButtonStyle={{
          color: "#FAFAFA",
          background: "none"
        }}
        onDecline={handleDeclineCookies}
        declineButtonText={t("decline")}
      >
        <div id="cookie-consent">
          {t("cookies-consent-1") + " "}
          <a id="cookies-link" href="https://www.heymandi.com/terms/cookies" target="_blank" rel="noreferrer">{t("cookies-consent-2")}</a>
        </div>
      </CookieConsent>
    </div>
  );
}
