import React, { useEffect, useState } from "react";

import "../css/congratulation.css";

import { useLottie } from "lottie-react";

import { useTranslation } from "react-i18next";

import { getLottie, postTrackingData } from "../utils";

export const Congratulation = ({ imagePath }) => {
  const { t } = useTranslation();
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [confettiData, setConfettiData] = useState(null);
  const [giftIntroData, setGiftIntroData] = useState(null);
  const [giftLoopData, setGiftLoopData] = useState(null);

  const isIPhone = /iPhone/.test(navigator.userAgent);
  const isAndroid = /android/i.test(navigator.userAgent);
  const isDesktop = window.matchMedia("(min-width: 1024px)").matches

  useEffect(() => {
    getLottie(`${imagePath}lottie/super_invite_gift_loop.json`).then(setGiftLoopData)
    getLottie(`${imagePath}lottie/super_invite_gift_intro.json`).then(setGiftIntroData)
    getLottie(`${imagePath}lottie/confetti.json`).then(setConfettiData)
    setConfettiSpeed(0.5);
    setIntroSpeed(0.76);
    postTrackingData("page_view", {"value":"page_success_register"})
  }, []);

  const styles = {
    "confetti-style": {
      zIndex: -1,
      position: "absolute",
      top: "13vh",
      left: "50vw",
      width: isDesktop ? "29%" : "170%",
      transform: "translate(-50%, -50%)",
    },
    "gift-style": {
      position: "absolute",
      width: isDesktop ? "11%" : "30%",
      top: "13vh",
      left: "50vw",
      transform: "translate(-50%, -50%)",
    },
  };

  const { View: confetti, setSpeed: setConfettiSpeed } = useLottie(
    {
      loop: true,
      autoplay: true,
      animationData: confettiData,
    },
    styles["confetti-style"]
  );

  const { View: gift, setSpeed: setIntroSpeed } = useLottie(
    {
      loop: isFirstTime ? false : true,
      autoplay: true,
      animationData: isFirstTime ? giftIntroData : giftLoopData,
      onComplete: () => setIsFirstTime(false),
    },
    styles["gift-style"]
  );

  const downloadButton = () => {
    const iOSButton = (
      <a href=" https://heymandi.onelink.me/YMjB/ip1nwpav">
        <img
          src={`${imagePath}appstore.png`}
          id="download-button"
          alt="Download on the App Store"
        />
      </a>
    );
    const androidButton = (
      <a href=" https://heymandi.onelink.me/YMjB/ip1nwpav">
        <img
          src={`${imagePath}googleplay.png`}
          id="download-button"
          alt="Get it on Google Play"
        />
      </a>
    );

    if (isDesktop) {
      return (
        <div id="download-button-container">
          {iOSButton}
          {androidButton}
        </div>
      );
    }

    if (isIPhone) {
      return iOSButton;
    } else if (isAndroid) {
      return androidButton;
    }
  };

  return (
    <div id="congratulation">
      {!isDesktop && (
        <div id="banner-container">
          <img id="heymandi-icon" src={`${imagePath}icon.png`} alt="" />
          <div id="banner">
            <div id="banner-header">
              Heymandi <br />
            </div>
            <div id="banner-desc">{t("banner-desc")}</div>
          </div>
          <button id="open-button">
            <a href="https://heymandi.onelink.me/YMjB/ip1nwpav">{t("open")}</a>
          </button>
        </div>
      )}
      <div id="logo-background">
        <img id="logo" src={`${imagePath}logo.png`} alt="" />
      </div>
      <div id="animation-container">
        {gift}
        {confetti}
      </div>
      <div id="congratulation-container">
        <div className="h1" id="congratulation-header">
          {t("congratulation")}
        </div>
        <div className="body-2" id="congratulation-content-1">
          {t("congratulation-1")}
        </div>
        <div className="body-2" id="congratulation-content-2">
          {t("congratulation-2")}
        </div>
        {downloadButton()}
      </div>
    </div>
  );
};
