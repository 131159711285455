import React, { useEffect } from "react";

import "../css/app.css";

import "../css/errorModal.css";
import { useTranslation } from "react-i18next";
import { postWhitelist, postTrackingData } from "../utils";

export const ErrorModal = ({ email, accessToken }) => {
  const { t } = useTranslation();
  const handleModalClose = () =>
    (document.getElementById("modal-background").style.display = "none");

  const handleRequestWhiteList = async (consent_to_marketing) => {
    postTrackingData("button_click", {"value":"request_domain_whitelist"})
    try {
      await postWhitelist({
        email,
        client: "web",
        access_token: accessToken,
        consent_to_marketing
      });
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    const handleModalBackgroundClick = (event) => {
      const modalBackground = document.getElementById("modal-background");
      if (event.target === modalBackground) {
        modalBackground.style.display = "none";
      }
    };
    window.addEventListener("click", handleModalBackgroundClick);

    return () =>
      window.removeEventListener("click", handleModalBackgroundClick);
  }, []);

  const modal = (
    <div id="mobile-modal" className="position-absolute">
      <div id="mobile-modal-content">
        <div className="h1">{t("error-1")}</div>
        <div className="body-1">{t("error-2")}</div>
        <div className="flexbox">
          <a id="email-link" className="body-1 fw-600" onClick={() => handleRequestWhiteList(true)}>
            {t("error-3")}
          </a>
        </div>
        <button
          id="cancel-button"
          className="body-1"
          onClick={() => handleRequestWhiteList(false)}
        >
          {t("cancel")}
        </button>
      </div>
    </div>
  );

  return (
    <div id="modal-background" onClick={handleModalClose}>
      {modal}
    </div>
  );
};
