const authAPI = "https://auth.heymandi.com/api/auth/request_token";
// const authAPI = "http://127.0.0.1:8000/api/auth/request_token";
const geoAPI = "https://pro.ip-api.com/json/?key=9v0kM9FcHSMakzD&fields=49410";
const SG = "https://api02.heymandi.com/api/auth/email";
const OG = "https://useu-api02.heymandi.com/api/auth/email";
// const SG = "https://tagging.heymandi.com/api/auth/email";
// const OG = "https://tagging.heymandi.com/api/auth/email";

const getRestAPI = async () => {
  try {
    if (process.env.NODE_ENV === "development") return SG;
    const res = await fetch(geoAPI);
    if (!res.ok) return SG;
    let { countryCode } = await res.json();
    return countryCode === "CA" || countryCode === "US" ? OG : SG;
  } catch (err) {
    console.log(err);
  }
};

export const getAccessToken = async () => {
  try {
    const res = await fetch(authAPI, {
      method: "GET",
      headers: {
        "Heymandi-Module": "heymandi/referral",
      },
    });
    const { token } = await res.json();
    return token;
  } catch (err) {
    console.log(err);
  }
};

export const getOTP = async (params) => {
  try {
    const restAPI = await getRestAPI();
    const res = await fetch(`${restAPI}/request_otp/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const verifyOTP = async (params) => {
  try {
    const restAPI = await getRestAPI();
    const res = await fetch(`${restAPI}/verify_otp/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const postWhitelist = async (params) => {
  try {
    const restAPI = await getRestAPI();
    const res = await fetch(`${restAPI}/request_whitelist/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });
    return res;
  } catch (err) {
    console.log(err);
  }
}

export const getLottie = async (url) => {
  try {
    const res = await fetch(url);
    const data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const postTrackingData = (eventName, payload) => {
  if (window.gtag === undefined) return;
  window.gtag("event", eventName, payload);
};