import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./en-US.json";
import tw from "./zh-TW.json";
import cn from "./zh-CN.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // Bindings for React
  .init({
    fallbackLng: "en-US",
    supportedLngs: ["en-US", "zh-TW", "zh-CN"],
    resources: { "en-US": en, "zh-TW": tw, "zh-CN": cn },
  })
  // testing purpose
  // .then((window.i18next = i18n), console.log(i18n.services.resourceStore.data))
  .catch((e) => console.log(e));

export default i18n;
